import axios from "axios";
import apiAddress from "../apiAddress";

export async function getBroadPreview(brdCntnId) {
  try {
    // 첫 번째 요청: getBroadText
    const brdCntnInf = await getBroadText(brdCntnId);
    if (Array.isArray(brdCntnInf) && brdCntnInf.length > 0) {
      const brdObj = brdCntnInf[0];

      // 파일 경로가 있는 경우 바로 반환
      if (brdObj.hasOwnProperty("filePath")) {
        return brdObj.filePath;
      } else {
        // 두 번째 요청: sendBroadPreview 후 다시 getBroadText
        await sendBroadPreview(brdCntnId); // sendBroadPreview는 결과를 사용하지 않음
        const brdCntnInf2 = await getBroadText(brdCntnId);

        if (Array.isArray(brdCntnInf2) && brdCntnInf2.length > 0) {
          const brdObj2 = brdCntnInf2[0];

          // 두 번째 요청에서도 파일 경로가 있는 경우 반환
          if (brdObj2.hasOwnProperty("filePath")) {
            return brdObj2.filePath;
          }
        }
      }
    }
    // 파일 경로가 없으면 빈 문자열 반환
    return "";
  } catch (error) {
    console.error(error);
    return ""; // 에러 발생 시 빈 문자열 반환
  }
}

export function getBroadText(brdCntnId) {
  return axios
    .post(
      apiAddress.broadTextGet,
      {
        brdCntnId: brdCntnId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      const broadCntn = response.data;
      if (Array.isArray(broadCntn) && broadCntn.length > 0) {
        return broadCntn;
      } else {
        return [];
      }
    });
}

export function sendBroadPreview(brdCntnId) {
  return axios
    .get(apiAddress.broadPreview, {
      params: {
        brdCntnId: brdCntnId,
      },
    })
    .then((response) => {
      const responseData = response.data;
      return responseData;
    });
}
