import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import apiAddress from "../../../services/apiAddress";
import BroadGroupRtu from "./BroadGroupRtu";

function BroadGroup() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => <input type="checkbox" style={{ cursor: "pointer" }} />,
  };
  const angleIconColumn = {
    id: "angleIcon",
    accessor: "angle",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      return row.original.angle ? (
        <FontAwesomeIcon icon={faAngleUp} />
      ) : (
        <FontAwesomeIcon icon={faAngleDown} />
      );
    },
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onClickRow(row) {
    // 행 클릭 시.
    const newData = data.map((item) => {
      if (item.groupId === row.groupId) {
        return { ...item, angle: !item.angle };
      }
      return item;
    });
    setData(newData);
  }

  /* 방송그룹 조회 API */
  async function fetchData() {
    try {
      // 그룹 조회.
      axios
        .post(
          apiAddress.groupGet,
          {
            groupId: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const group = response.data.filter(
            (item) => item.groupType === "BRD" && item.useYn === "Y"
          );

          const fetchRtu = async (item) => {
            try {
              const response = await axios.post(
                apiAddress.groupEquipGet,
                {
                  groupId: item.groupId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              const responseData = response.data;
              return { ...item, rtuData: responseData, angle: false };
            } catch (e) {
              console.log(e);
              return null;
            }
          };
          const fetchGroupRtu = async () => {
            const groupRtu = await Promise.all(group.map(fetchRtu));
            const brdGroup = groupRtu.filter((item) => item.rtuData.length > 0);

            const column = [
              checkColumn,
              columnHelper.accessor("groupNm", {
                header: "그룹명",
                size: "auto",
              }),
              angleIconColumn,
            ];
            setData(brdGroup);
            setColumns(column);
          };
          fetchGroupRtu();
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {data.length > 0 ? (
        <table className="react_table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      width: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      minWidth: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      maxWidth: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <React.Fragment key={row.id}>
                <tr
                  key={row.id}
                  className={index % 2 === 0 ? "tr_even" : "tr_odd"}
                  onClick={() => {
                    onClickRow(row.original);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      onClick={(e) => {
                        if (cell.column.id === "check") {
                          e.stopPropagation();
                        }
                      }}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        minWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        maxWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "check" ||
                            cell.column.id === "angleIcon") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
                {row.original.angle && row.original.rtuData.length > 0 && (
                  <tr key="sub_table-tr">
                    <td
                      className="sub_table-td"
                      colSpan={3}
                      style={{ padding: "0" }}
                    >
                      <BroadGroupRtu rtuData={row.original.rtuData} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="brd_data_none_container">
          <div>등록된 그룹이 없습니다</div>
        </div>
      )}
    </>
  );
}

export default BroadGroup;
